<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{operationTitle}}
                    </template>
                    <template v-slot:toolbar>
                        <b-input-group-append>
                            <router-link to="/users/create"
                                         v-b-tooltip.hover v-if="$global.hasPermission('usersstore')"
                            >
                                <b-button class="btn btn-primary font-weight-bolder"
                                          v-b-tooltip.hover :title="`Add New`"
                                          variant="success"><i class="fa fa-plus fa-sm"></i> Add New
                                </b-button>
                            </router-link>
                            <b-button
                                :class="checkFilterCount ? `btn btn-danger text-white font-weight-bolder ml-2` : 'btn btn-light-primary font-weight-bolder ml-2'"
                                v-b-toggle.sidebar-backdrop-users>
                                <i class="fa fa-filter fa-sm"></i> Filter

                            </b-button>
                        </b-input-group-append>
                    </template>
                    <template v-slot:body>
                        <v-card>
                            <div class="users-table" v-if="$global.hasPermission('usersview')">
                                <div class="w-100 mb-6">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_length"><label>Show
                                                <b-form-select :options="[10, 50, 100]"
                                                               class="datatable-select"
                                                               size="sm"
                                                               v-model="pagination.totalPerPage"></b-form-select>
                                                entries</label></div>

                                        </div>
                                        <!--                                        <div class="col-sm-12 col-md-6 mt-2">-->
                                        <!--                                            <span v-for="(value,key) in filters"-->
                                        <!--                                                  :key="key">-->
                                        <!--                                                <span v-if="_.isArray(value) && key !== 'visible'">-->
                                        <!--                                                    <b-tag class="py-2 text-capitalize" v-if="value.length > 0"> {{$global.removeSymbols(key)}}-->
                                        <!--                                                        <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                           @click="clearFilter(key, [])"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                                <span v-else>-->
                                        <!--                                                    <b-tag class="py-2"-->
                                        <!--                                                           v-if="value && key !== 'visible'"> <span-->
                                        <!--                                                        class="text-capitalize">{{$global.removeSymbols(key)}}</span>-->
                                        <!--                                                    <i class="fa fa-times-circle ml-2"-->
                                        <!--                                                       @click="clearFilter(key , null)"></i>-->
                                        <!--                                                    </b-tag>-->
                                        <!--                                                </span>-->
                                        <!--                                            </span>-->
                                        <!--                                        </div>-->
                                        <div class="col-sm-12 col-md-6">
                                            <div class="dataTables_filter" id="kt_datatable_filter"><label>Search:
                                                <b-form-input class="form-control form-control-sm ml-2 w-25"
                                                              label="Search"
                                                              type="search"
                                                              v-model="search"
                                                              v-on:keyup.enter="handleSearch"></b-form-input>
                                            </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="users-table">

                                <v-data-table
                                    :headers="columns"
                                    :items="dataSource"
                                    :items-per-page="parseInt(pagination.totalPerPage)"
                                    :loading="loading"
                                    class="table table-head-custom table-vertical-center table-responsive"
                                    hide-default-footer
                                    ref="table"
                                    responsive
                                >
                                    <template v-slot:item.id="record">
                                        {{ dataSource.length - record.index }}
                                    </template>
                                    <template v-slot:item.roles="{item}">
                                        <b-tag :key="index" v-for="(role, index) in item.roles"
                                               variant="primary"> {{ role.name }}
                                        </b-tag>
                                    </template>
                                    <template class="action-column" v-slot:item.action="{item}">
                                        <router-link :to="`users/edit/${item.id}`"
                                                     v-if="item.roles.length">
                                            <a size="sm" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                               v-if="$global.hasPermission('usersupdate') && (authUser.id !== item.id) &&  (item.roles[0].name !== 'MasterGuestUser' && item.roles[0].name !== 'SubGuestUser')">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                      class="action-edit-button"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <router-link :to="`users/edit/${item.id}`" v-else>
                                            <a class="btn btn-icon btn-light btn-hover-primary btn-sm"
                                               size="sm"
                                               v-if="$global.hasPermission('usersupdate') && (authUser.id !== item.id)">
                                                <span
                                                    class="svg-icon svg-icon-md svg-icon-primary">
                                                  <!--begin::Svg Icon-->
                                                  <inline-svg
                                                      class="action-edit-button"
                                                      src="/media/svg/icons/Communication/Write.svg"
                                                  ></inline-svg>
                                                    <!--end::Svg Icon-->
                                                </span>
                                            </a>
                                        </router-link>
                                        <a size="sm" class="ml-2 btn btn-icon btn-light btn-hover-primary btn-sm"
                                           v-show="$global.hasPermission('usersdestroy')"
                                           @click="handleDeleteOperation(item.id)">
                                            <span
                                                class="svg-icon svg-icon-md svg-icon-primary">
                                              <!--begin::Svg Icon-->
                                              <inline-svg
                                                  src="/media/svg/icons/General/Trash.svg" class="action-delete-button"
                                              ></inline-svg>
                                                <!--end::Svg Icon-->
                                            </span>
                                        </a>
                                    </template>
                                </v-data-table>
                                <div class="row">
                                    <div class="pagination-box">
                                        <b-pagination
                                            :per-page="pagination.totalPerPage"
                                            :total-rows="pagination.total"
                                            size="lg"
                                            v-model="pagination.current"
                                        ></b-pagination>
                                    </div>
                                </div><!-- /.pull-right -->
                            </div>
                        </v-card>
                    </template>
                </KTCard>

                <template>
                    <div>
                        <b-sidebar
                            :backdrop-variant="`transparent`"
                            backdrop
                            id="sidebar-backdrop-users"
                            right
                            shadow
                            title="Filters"
                        >
                            <template #header="{ hide }">
                                <b-col sm="10">
                                    <strong id="sidebar-backdrop-leads___title__">Filters</strong>
                                </b-col>
                                <b-col sm="2">
                                    <button @click="hide();"
                                            class="btn btn-xs btn-icon btn-light btn-hover-primary text-right">
                                        <i class="ki ki-close icon-xs text-muted"></i>
                                    </button>
                                </b-col>
                            </template>
                            <template #footer="{ hide }">
                                <div
                                    class="d-flex justify-center cursor-pointer w-full border-top align-items-center px-3 py-4">
                                    <b-button @click="hide();handleResetFilterClick()"
                                              class="btn btn-light-primary font-weight-bolder ml-2">
                                        <i class="fa fa-broom fa-sm"></i> Clear All
                                    </b-button>
                                </div>
                            </template>
                            <div class="px-10 py-4">
                                <b-col sm="12">
                                    <b-form-group
                                        label="Name"
                                        label-for="name">
                                        <b-form-input class="mb-2" id="filter-name"
                                                      v-model="filters.name"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        label="Email"
                                        label-for="email">
                                        <b-form-input class="mb-2" id="filter-email"
                                                      v-model="filters.email"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                                <b-col sm="12">
                                    <b-form-group
                                        label="Phone"
                                        label-for="phone">
                                        <b-form-input class="mb-2" id="filter-phone"
                                                      v-model="filters.phone"></b-form-input>
                                    </b-form-group>
                                </b-col><!--/b-col-->
                            </div>
                        </b-sidebar>
                    </div>
                </template>
                <!-- /.filter-container -->
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import ListingMixin from "@/assets/js/components/ListingMixin";
    import Error from "@/core/services/Error";
    import {request} from "@/core/services/Request";
    import ModuleMixing from "./module.mixin"

    const DEFAULT_FILTER_STATE = {
        name: null,
        email: null,
        phone: null,
    };

    export default {
        name: "UserList",
        mixins: [ListingMixin, ModuleMixing],
        data() {
            return {
                filters: {...DEFAULT_FILTER_STATE},
            }
        },
        components: {
            KTCard,
        },
        methods: {
            handleResetFilterClick() {
                this.filters = {...DEFAULT_FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.checkFilterCountStatus()
            },
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
